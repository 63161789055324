import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { AuthContext } from '../contexts/authContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

/**
 * 呼叫產生隨機變數的 API
 * 
 * @returns 
 */
export default function RandomNumber() {

  const classes = useStyles()
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [error, setError] = useState('')
  const [response, setResponse] = useState('')

  /**
   * API Gateway 上共設定了三個 Stages:
   *  1. open: 不檢查任何授權
   *  2. idToken: Request headers 裡必須有「Authorization: ${idToken}」
   *  3. accessToken: Request headers 裡必須有「Authorization: ${accessToken}」
   */
  useEffect(() => {
    const fetchRandomNumber = async () => {
      const api = 'https://44rduwjpw7.execute-api.ap-northeast-1.amazonaws.com/idToken/random-generator';
      const response = await fetch(api, {
        headers: {
          'content-type': 'application/json',
          'authorization': `${auth.sessionInfo?.idToken}`,
        },
        method: 'GET',
      }).then((response: any) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Network response was not ok.');
      }).catch((error: any) => {
        console.log('There has been a problem with your fetch operation: ', error.message);
        return error;
      });
      if (response.hasOwnProperty('randomNumber')) {
        setError('')
        setResponse(JSON.stringify(response.randomNumber))
      } else {
        setError(response.message);
        setResponse('');
      }
    }
    fetchRandomNumber()
  }, [])

  const showRadomNumber = (
    <>
      <Typography variant="h5">{`Random Number`}</Typography>
      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>
      <Box m={4}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h3">{response}</Typography>
        </Grid>
      </Box>
      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              Close
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 16 }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {/* Title */}
            <Box m={3}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Typography variant="h3">Random Number</Typography>
              </Grid>
            </Box>
            {showRadomNumber}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
